import React, { Component } from 'react'
import PropTypes from 'prop-types';
import AppNavigation from "../Navigation/Routes";
import Footer from "./Footer";
import MainMenu from "./MainMenu";
import ScrollToTop from './ScrollToTop';
import { HelmetProvider } from "react-helmet-async";
import BackgroundCubes from "./BackgroundCubes";
import { withRouter } from 'react-router-dom'; // Import withRouter

class App extends Component {
  render() {
    const { location } = this.props; // Destructure location from props

    // Conditionally render BackgroundCubes based on the current path
    const shouldShowBackgroundCubes = !location.pathname.startsWith('/product/');

    return (
      <HelmetProvider>
        <div className="d-flex flex-column h-100 z-index-5 app-container">
          {shouldShowBackgroundCubes && <BackgroundCubes />}
          <ScrollToTop />
          <MainMenu history={this.props.history} />
          <div className="flex-fill app-content z-index-1">
            <AppNavigation />
          </div>
          <Footer />
        </div>
      </HelmetProvider>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
  history: PropTypes.object,
  location: PropTypes.object.isRequired, // Add location prop type validation
};

export default withRouter(App); // Wrap the App component with withRouter
