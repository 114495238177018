import React from 'react';
import {IoCubeOutline} from "react-icons/io5";
import PropTypes from "prop-types";

const Cube = ({ top, left }) => {
  return  <IoCubeOutline className="cube text-primary z-index-m-1" style={{ top: `${top}%`, left: `${left}%` }} />;
};

Cube.propTypes = {
  top: PropTypes.number.isRequired,  // Define top as a required number
  left: PropTypes.number.isRequired, // Define left as a required number
};

export default Cube;

// const CubeIcon = ({ top, left }) => {
//   return (
//     <div className="cube" style={{ top: `${top}%`, left: `${left}%`, position: 'absolute' }}>
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         width="50"
//         height="50"
//         viewBox="0 0 512 512"
//         fill="currentColor"
//         stroke="#FF6B00"
//         strokeWidth="23" /* Thinner stroke width */
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       >
//         <path fill="none" d="M448 341.37V170.61A32 32 0 0 0 432.11 143l-152-88.46a47.94 47.94 0 0 0-48.24 0L79.89 143A32 32 0 0 0 64 170.61v170.76A32 32 0 0 0 79.89 369l152 88.46a48 48 0 0 0 48.24 0l152-88.46A32 32 0 0 0 448 341.37z"></path>
//         <path fill="none" d="m69 153.99 187 110 187-110m-187 310v-200"></path>
//       </svg>
//     </div>
//   );
// };
//
// export default CubeIcon;
