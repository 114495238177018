import React from 'react';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
import { IoStar } from "react-icons/io5";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const ProjectsSection = ({ projects }) => {
  return (
    <section className="">
      <Container>
        <Row className="">
          {projects.map((project, index) => (
            <Col md={4} className={`${index % 2 === 0 ? 'ms-auto' : 'me-auto'} mb-4 mb-md-0`} key={index}>
              <Card className="shadow-inner-xl border-primary-subtle hvr-grow">
                <Link to={`/product/${project.slug}`}>
                  <img className="img-fluid w-100 rounded-2" src={project.imageSrc} alt={project.slogan} />
                </Link>
                <Card.Body className="">
                  <p className="text-primary fw-bolder mb-1">{project.category}</p>
                  <div className="d-flex align-items-center">
                    <div>
                      <Link to={`/product/${project.slug}`} className="link-dark">
                        <div className="fw-bold h4 mb-2">{`${project.name} - ${project.slogan}`}</div>
                      </Link>
                    </div>
                  </div>
                  <p className="mb-0">{project.description}</p>
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <div className="d-flex">
                      {[...Array(project.rating)].map((_, i) => (
                        <IoStar className="text-primary me-1 icon-xs" key={i} />
                      ))}
                    </div>
                    <div><Badge bg="primary p-2 fw-bolder">v{project.version}</Badge></div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

ProjectsSection.propTypes = {
  projects: PropTypes.array.isRequired,  // Define top as a required number
};

export default ProjectsSection;
