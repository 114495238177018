import React from 'react';
import Cube from './Cube';

const BackgroundCubes = () => {
  return (
    <div className="background-cubes z-index-m-1 d-none d-md-block">
      <Cube top={12} left={20} />
      <Cube top={30} left={72} />
      <Cube top={70} left={80} />
      <Cube top={40} left={10} />
      <Cube top={90} left={23} />
    </div>
  );
};

export default BackgroundCubes;
