import React from 'react';
import { IoLogoTwitter, IoLogoGithub, IoLogoFacebook } from 'react-icons/io5';
import Images from "../Styles/Images";
import AppConfig from "../Config/AppConfig";

const Footer = () => {
  return (
    <footer className="footer py-5 bg-white">
      <div className="container">
        <div className="">
          <div className="mx-auto ">
            <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-center">
              <div className="">
                <div className="headline d-flex">
                  <a href="#">
                    <img className="brand-logo d-inline-block align-top"
                         src={Images.logo_wide_light}
                         alt="Site logo" />
                  </a>
                </div>
              </div>
              <div className="d-flex justify-content-md-center align-items-center mt-4 mt-md-0 footer-social-links">

                <a className="ms-3" href="https://github.com/theqdev" rel="noreferrer" target="_blank" alt="Github" title="Github">
                  <IoLogoGithub size={26} />
                </a>

                <a className="ms-3" href="https://www.facebook.com/qwebdev" rel="noreferrer" target="_blank" alt="Facebook" title="Facebook">
                  <IoLogoFacebook size={26}/>
                </a>

                <a className="ms-3" href="https://twitter.com/Qwebdev" rel="noreferrer" target="_blank" alt="Twitter" title="Twitter">
                  <IoLogoTwitter size={26}/>
                </a>

              </div>
            </div>
          </div>

          <hr/>

          <div className="">
            <div className="copyRightInfo d-flex flex-column-reverse flex-md-row d-md-flex justify-content-md-between">
              <div className="d-flex align-items-center justify-content-center mt-3 mt-md-0">
                <p className="mb-0">&copy; {new Date().getFullYear()} {AppConfig.AppName} - All rights reserved. </p>
              </div>
              <div className="d-flex justify-content-center">
                <span>Made with 💗 in our labs.</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </footer>

  );
};

export default Footer;
