import logo_wide_dark from '../Images/qdev-logo-white.png';
import logo_wide_light from '../Images/qdev-logo.webp';
import code_preview from '../Images/code-preview.svg';
import code_preview_2 from '../Images/code-preview-2.png';
import code_preview_3 from '../Images/code-preview-3.png';
import envato_logo from '../Images/logos/envato-logo-square.svg';
import envato_logo_wide from '../Images/logos/envato-logo-wide-cropped.svg';
import envato_logo_black from '../Images/logos/envato-logo-square-black.svg';
import envato_logo_primary from '../Images/logos/download.svg';
import fans_logo from '../Images/logos/fans-square.png';
import fans_preview from '../Images/logos/fans-preview.webp';
import jobs_logo from '../Images/logos/jobs-square.png';
import jobs_preview from '../Images/logos/jobs-preview.webp';
import envato_logo_white from '../Images/logos/envato-logo-white.svg';
import codecanyon_header from '../Images/codecanyon-img-prev.png';

const images = {
  logo_wide_dark,
  logo_wide_light,
  code_preview,
  code_preview_3,
  code_preview_2,
  envato_logo,
  envato_logo_wide,
  envato_logo_black,
  envato_logo_primary,
  fans_logo,
  jobs_logo,
  fans_preview,
  jobs_preview,
  envato_logo_white,
  codecanyon_header
}

export default images;
