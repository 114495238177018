import React, {useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import {Card} from 'react-bootstrap';
import '../Styles/home.scss';
import {getDocumentTitle, setDocumentTitle, useCurrentUrl} from "../Utils/helper";
import MetaTags from "../Components/MetaTags";
import Images from "../Styles/Images";

const Contact = () => {
  const pageTitle = "Contact us";
  const pageDescription = "For any further questions, including partnership opportunities, please send us a message via Envato Market, and we’ll get back to you as soon as possible.";
  const currentUrl = useCurrentUrl(); // Get the current URL using the utility function
  useEffect(() => {
    setDocumentTitle(pageTitle);
  }, []); // The empty dependency array ensures this runs only on component mount

  return (
    <div>
      <MetaTags
        title={getDocumentTitle(pageTitle)}
        description={pageDescription}
        ogTitle={getDocumentTitle(pageTitle)}
        ogDescription={pageDescription}
        ogUrl={currentUrl}
        ogImage={Images.codecanyon_header}
      />
      <div>
        <section className="py-4 py-md-7">
          <Container>
            <Row className="align-items-center">
              <Col lg={8} className="mx-auto text-center pb-4 pb-md-0">
                <div className="ms-3 mb-md-5">
                  <h2 className="fw-bold">Contact us</h2>
                  <p className="lead mt-3">
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    For any further questions, including partnership opportunities, please send us a message via Envato Market, and we’ll get back to you as soon as possible.
                  </p>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={8} className="mx-auto">
                <Row>
                  <Col md={6} className="mb-4 mb-md-0">
                    <div className="d-flex h-100">
                      <Card className="bg-body-secondary border-0 shadow-sm flex-fill">
                        <Card.Body className="p-4 d-flex flex-column">
                          <h5 className="fw-bolder">Pre-sale questions</h5>
                          <ul className="py-4 lh-1p6">
                            <li>General questions</li>
                            <li>Pre-sales questions</li>
                          </ul>

                          <div className="mb-2 mt-auto">
                            <p className="small text-muted ms-2 mb-2">You can either leave us a public comment or send us a direct message.</p>
                          </div>

                          <a href="https://codecanyon.net/user/ic0de/portfolio" target="_blank" rel="noopener noreferrer">
                            <Button variant="primary" className="w-100 mt-auto">Contact us</Button>
                          </a>

                        </Card.Body>
                      </Card>
                    </div>
                  </Col>

                  <Col md={6} className="mb-4 mb-md-0">
                    <div className="d-flex h-100">
                      <Card className="bg-body-secondary border-0 shadow-sm flex-fill">
                        <Card.Body className="p-4 d-flex flex-column">
                          <h5 className="fw-bolder">Product support</h5>
                          <ul className="py-4 lh-1p6">
                            <li>General questions</li>
                            <li>Product support</li>
                          </ul>

                          <div className="mb-2 mt-auto">
                            <p className="small text-muted ms-2 mb-2">You can either leave us a public comment or send us a direct message.</p>
                            <p className="small text-muted ms-2">
                              Support scope is limited to <a href="https://help.market.envato.com/hc/en-us/articles/208191263-What-is-Item-Support" target="_blank" rel="noopener noreferrer">Envato terms</a>.
                            </p>
                          </div>
                          <a href="https://codecanyon.net/user/ic0de#contact" target="_blank" rel="noopener noreferrer">
                            <Button variant="primary" className="w-100 mt-auto">Contact us</Button>
                          </a>

                        </Card.Body>
                      </Card>
                    </div>
                  </Col>


                </Row>

                <Row className="mt-2 mt-md-4">
                  <div className="d-flex h-100">
                    <Card className="bg-body-secondary border-0 shadow-sm flex-fill">
                      <Card.Body className="p-4 d-flex flex-column">
                        <h5 className="fw-bolder">Support scope</h5>
                        <p>Generally speaking, the scope of support does not cover installation, configuration, updates,
                          or maintenance of your website instance.</p>
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        <p>However, we’re more than happy to assist you with any issues you might encounter during the
                          initial setup and throughout your instance’s lifetime.</p>
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        <p>If you're reaching out for support, please include details such as:</p>
                        <ul className="lh-1p6">
                          <li>A screenshot or video of the error you're encountering</li>
                          <li>The latest error entry from the <code>storage/logs/laravel.log</code> file, or the entire
                            file
                          </li>
                          <li>Your site link or, if possible, cPanel credentials</li>
                        </ul>

                        {/*<Button variant="primary" className="w-50 mt-auto ms-auto">Contact us</Button>*/}
                        <p>Our official response time is 5 business days, but we’ll get back to you as soon as possible, depending on the ticket scope.</p>
                      </Card.Body>
                    </Card>
                  </div>
                </Row>


              </Col>
            </Row>


          </Container>
        </section>
      </div>


      {/*<ContactUsPlainCard/>*/}
    </div>
  );
}

export default Contact;
