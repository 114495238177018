import React, {useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import {Card} from 'react-bootstrap';
import '../Styles/home.scss';
import {getDocumentTitle, setDocumentTitle, useCurrentUrl} from "../Utils/helper";
import MetaTags from "../Components/MetaTags";
import Images from "../Styles/Images";

const Services = () => {
  const pageTitle = "Services";
  const pageDescription = "Let us handle the hard stuff. We can assist with product installation for an additional fee, whether it's in a new environment or an existing one.";
  const currentUrl = useCurrentUrl(); // Get the current URL using the utility function
  useEffect(() => {
    setDocumentTitle(pageTitle);
  }, []); // The empty dependency array ensures this runs only on component mount

  return (
    <div>
      <MetaTags
        title={getDocumentTitle(pageTitle)}
        description={pageDescription}
        ogTitle={getDocumentTitle(pageTitle)}
        ogDescription={pageDescription}
        ogUrl={currentUrl}
        ogImage={Images.codecanyon_header}
      />
      <section className="py-4 py-md-7">
        <Container>
          <Row className="align-items-center">
            <Col lg={8} className="mx-auto text-center pb-4 pb-md-0">
              <div className="ms-3 mb-md-5">
                <h2 className="fw-bold">Services</h2>
                <p className="lead mt-3">
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Let us handle the hard stuff. We can assist with product installation for an additional fee, whether it's in a new environment or an existing one.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={8} className="mx-auto">
              <Row>
                <Col md={6} className="mb-4 mb-md-0">
                  <div className="d-flex h-100">
                    <Card className="bg-body-secondary border-0 shadow-sm flex-fill">
                      <Card.Body className="p-4 d-flex flex-column">
                        <h5 className="fw-bolder">Full setup</h5>
                        <ul className="py-4 lh-1p6">
                          <li>Cyberpanel on <a href="https://m.do.co/c/dec4efd6a4d7D" target="_blank" rel="noreferrer">DigitalOcean</a></li>
                          <li>PHP & Server configuration</li>
                          {/*<li>Auto-SSL, Cronjobs, Emails</li>*/}
                          <li>Software installation</li>
                          <li>Software configuration</li>
                        </ul>

                        <div className="mb-2 mt-auto">
                          <p className="small text-muted ms-2 mb-2">Software configuration is limited to the information
                            provided by the customer.</p>
                          <p className="small text-muted ms-2">This package can be deployed on existing cPanel-based
                            servers or alternative solutions.</p>
                        </div>
                        <a href="https://codecanyon.net/user/ic0de#contact" target="_blank" rel="noopener noreferrer">
                          <Button variant="primary" className="w-100 mt-auto">Contact us</Button>
                        </a>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>

                <Col md={6} className="mb-4 mb-md-0">
                  <div className="d-flex h-100">
                    <Card className="bg-body-secondary border-0 shadow-sm flex-fill">
                      <Card.Body className="p-4 d-flex flex-column">
                        <h5 className="fw-bolder">Script install & config</h5>
                        <ul className="py-4 lh-1p6">
                          <li>Software installation</li>
                          <li>Software configuration</li>
                        </ul>

                        <div className="mb-2 mt-auto">
                          <p className="small text-muted ms-2 mb-2">Software configuration is limited to the information
                            provided by the customer.</p>
                          <p className="small text-muted ms-2 mb-2">cPanel or alternative panel is required. No barebone
                            VPS.</p>
                          <p className="small text-muted ms-2">This package can be deployed on existing cPanel-based
                            servers or alternative solutions.</p>
                        </div>
                        <a href="https://codecanyon.net/user/ic0de#contact" target="_blank" rel="noopener noreferrer">
                          <Button variant="primary" className="w-100 mt-auto">Contact us</Button>
                        </a>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>

              </Row>

              <Row className="mt-2 mt-md-4">
                <div className="d-flex h-100">
                  <Card className="bg-body-secondary border-0 shadow-sm flex-fill">
                    <Card.Body className="p-4 d-flex flex-column">
                      <h5 className="fw-bolder">Custom work</h5>
                      <p>Unfortunately, we are currently unavailable for custom work services.</p>
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      <p>However, don't hesitate to send us a message with more details about your project, and we may
                        be able to connect you with a few developers familiar with the tech stack.</p>
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      <p>Be sure to include details such as:</p>
                      <ul className="lh-1p6">
                        <li>Project documentation, details, or specifications</li>
                        <li>Estimated project timeline</li>
                        <li>Estimated project budget</li>
                      </ul>
                      {/*<Button variant="primary" className="w-50 mt-auto ms-auto">Contact us</Button>*/}
                      <p>And we will get back to you with more information or potential developer contacts.</p>
                    </Card.Body>
                  </Card>
                </div>
              </Row>


            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Services;
