import React, {useEffect} from 'react';
import '../Styles/home.scss';
import ProjectsSection from "../Components/ProjectsSection";
// import BackgroundCubes from "../Components/BackgroundCubes";
import AppConfig from '../Config/AppConfig'
import HomeHeader from "../Components/HomeHeader";
import {getDocumentTitle, setDocumentTitle, useCurrentUrl} from "../Utils/helper";
import MetaTags from "../Components/MetaTags";
import Images from '../Styles/Images';

const Home = () => {
  const pageTitle = "Bring your next idea to life with our ready-to-go SaaS solutions!";
  const pageDescription = "Qdev Techs is a small software company specializing in web and mobile development. Bring your next idea to life with our ready-to-go SaaS solutions!";
  const currentUrl = useCurrentUrl(); // Get the current URL using the utility function
  useEffect(() => {
    setDocumentTitle(pageTitle);
  }, []); // The empty dependency array ensures this runs only on component mount
  return (
    <div>
      <MetaTags
        title={getDocumentTitle(pageTitle)}
        description={pageDescription}
        ogTitle={getDocumentTitle(pageTitle, true)}
        ogDescription={pageDescription}
        ogUrl={currentUrl}
        ogImage={Images.codecanyon_header}
      />
      {/*<BackgroundCubes/>*/}
      <HomeHeader/>
      <div className="pb-6">
        <ProjectsSection projects={AppConfig.projectsData}/>
      </div>
    </div>
  );
}
export default Home;
