import AppConfig from '../Config/AppConfig'
import {useLocation} from "react-router-dom";

export const getProductBySlug = (slug) => {
  return AppConfig.projectsData.find(product => product.slug === slug);
};

// You can also generalize it to search by any property
export const getProductByProperty = (property, value) => {
  return AppConfig.projectsData.find(product => product[property] === value);
};

export const getDocumentTitle = (title, overrideTemplate = false) => {
  const siteName = AppConfig.AppName; // This could be dynamic or set from environment variables
  if(overrideTemplate === true){
    return `${title}`;
  }
  return `${title} - ${siteName}`;
};

export const setDocumentTitle = (title, overrideTemplate = false) => {
  document.title = getDocumentTitle(title, overrideTemplate)
};

export const useCurrentUrl = () => {
  const location = useLocation();
  return `${window.location.origin}${location.pathname}${location.search}${location.hash}`;
};
