import React from 'react';
import { Container, Row, Col, Card, Button, Image } from 'react-bootstrap';
import {IoCheckmarkOutline} from 'react-icons/io5';
import Images from "../Styles/Images";
import PropTypes from 'prop-types';

const PricingSection = ({ product }) => {
  return (
    <section className="py-7 bg-body-secondary">
      <Container className="pb-6">
        <Row className="pb-7">
          <Col md={6} className="mx-auto text-center">
            <h3 className="fw-bolder">Best no-tricks pricing</h3>
            <p className="lead mt-3 mb-0">Buy once, own forever. Our self-hosted applications means you control everything, the code and the data.</p>
          </Col>
        </Row>
        <Row>
          <Card className="w-100 border-0">
            <Row>
              <Col lg={8}>
                <Card.Body className="py-4">
                  <h3 className="text-gradient text-primary">Lifetime Membership</h3>
                  <p className="mb-0">
                    Updates are on the house. Full license terms are available at <a href="https://codecanyon.net/licenses/standard" target="_blank" rel="noopener noreferrer">Envato market</a>.
                  </p>
                  <Row className="pt-4 pb-3">
                    <Col lg={3} className="col-12 d-flex align-items-center">
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      <h6 className="text-dark text-uppercase fw-bold">What's included</h6>
                    </Col>
                    <Col xs={6}>
                      <hr className="horizontal dark" />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6} className="ps-0">
                      <div className="d-flex align-items-center p-2">
                        <div className="icon icon-shape icon-xs rounded-circle bg-gradient-primary opacity-6 shadow text-center d-flex justify-content-center align-items-center text-white">
                          <IoCheckmarkOutline size={14} className="opacity-10" />
                        </div>
                        <div>
                          <span className="ps-3">For use in a single end product</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center p-2">
                        <div className="icon icon-shape icon-xs rounded-circle bg-gradient-primary opacity-6 shadow text-center d-flex justify-content-center align-items-center text-white">
                          <IoCheckmarkOutline size={14} className="opacity-10" />
                        </div>
                        <div>
                          <span className="ps-3">Free updates</span>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} md={6} className="ps-sm-2 ps-0">
                      <div className="d-flex align-items-center p-2">
                        <div className="icon icon-shape icon-xs rounded-circle bg-gradient-primary opacity-6 shadow text-center d-flex justify-content-center align-items-center text-white">
                          <IoCheckmarkOutline size={14} className="opacity-10" />
                        </div>
                        <div>
                          <span className="ps-3">Source code access</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center p-2">
                        <div className="icon icon-shape icon-xs rounded-circle bg-gradient-primary opacity-6 shadow text-center d-flex justify-content-center align-items-center text-white">
                          <IoCheckmarkOutline size={14} className="opacity-10" />
                        </div>
                        <div>
                          <span className="ps-3">Payment system</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Col>
              <Col lg={4} className="my-auto">
                <Card.Body className="text-center">
                  <p className="mt-sm-4 mt-0 mb-0">Pay once, own it forever</p>
                  <p className="mt-0 fs-1 fw-bolder mb-0">
                    <small>$</small>{product.extendedPrice}
                  </p>
                  <div className="d-flex justify-content-center align-items-center mt-2">
                    <a href={product.envatoUrl} target="_blank" className="me-2" rel="noopener noreferrer">
                      <Button variant="primary" className="envato-button">
                        <div className="d-flex align-items-center">
                          <Image src={Images.envato_logo_white}
                                 className="img-fluid"
                                 style={{
                                   height: '32px',
                                   marginRight: '.5rem'
                                 }}
                                 alt="Custom Icon"
                          />
                          Buy on Envato
                        </div>
                      </Button>
                    </a>
                  </div>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Row>
      </Container>
    </section>
  );
};

PricingSection.propTypes = {
  product: PropTypes.object.isRequired,  // Define top as a required number
};

export default PricingSection;
