import React from 'react';

const HomeHeader = () => {
  return (
    <div className="d-flex justify-content-center py-6 home-header text-center">
      <div className="col-12 col-md-6 col-lg-6 col-xl-4">

        <h1 className="fw-bolder fs-xxl">Qdev Techs</h1>
        <h2 className="fw-bold text-primary">Ideas / Design / Development</h2>

        <p className="py-4 pb-1 pb-md-4 lead">
          Qdev Techs is a small software company specializing in web and mobile development. Bring your next idea to life with our ready-to-go SaaS solutions!
        </p>

        {/*
        <a href="https://github.com/theqdev/react-big-bang" rel="noopener noreferrer" target="_blank">
          <Link to="documentation">
            <Button variant="primary" className="envato-button">
              <Image
                src={Images.envato_logo_primary}
                className="img-fluid"
                style={{
                  height: '32px',
                  marginRight: '.5rem'
                }}
                alt="Custom Icon"
              />
              Envato market
            </Button>
          </Link>
        </a>

        <a href="https://github.com/theqdev/react-big-bang" rel="noopener noreferrer" target="_blank">
          <Button variant="outline-primary" className="">
            <Image
              src={Images.envato_logo}
              className="img-fluid"
              style={{
                height: '32px',
                marginRight: '.5rem'
              }}
              alt="Custom Icon"
            />
            Envato
          </Button>
        </a>

        <a href="https://github.com/theqdev/react-big-bang" rel="noopener noreferrer" target="_blank">
          <Button variant="outline-success" className="">
            <Image
              src={Images.envato_logo_wide}
              className="img-fluid"
              style={{
                height: '24px',
                marginTop: '.3rem',
                marginBottom: '.2rem',
                marginLeft: '.5rem',
                marginRight: '.5rem'
              }}
              alt="Custom Icon"
            />
          </Button>
        </a>

        <Link to="documentation" className="me-2">
          <Button variant="primary" className="envato-button">
            <div className="d-flex align-items-center">
              <Image
                src={Images.envato_logo_white}
                className="img-fluid"
                style={{
                  height: '32px',
                  marginRight: '.5rem'
                }}
                alt="Custom Icon"
              />
              Envato market
            </div>
          </Button>
        </Link>

        <Link to="documentation" className="me-2">
          <Button variant="outline-primary" className="envato-button">
            <div className="d-flex align-items-center">
              <Image
                src={Images.envato_logo_primary}
                className="img-fluid"
                style={{
                  height: '32px',
                  marginRight: '.5rem'
                }}
                alt="Custom Icon"
              />
              Envato market
            </div>
          </Button>
        </Link>

        <Link to="documentation">
          <Button variant="primary" py-15>Get started</Button>
        </Link>
        */}

        {/*<Link to="documentation">*/}
        {/*  <Button variant="link">Learn more</Button>*/}
        {/*</Link>*/}

      </div>
    </div>
  );
};

export default HomeHeader;
