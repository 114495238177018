import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { IoColorWandOutline, IoServerOutline, IoCodeOutline, IoCogOutline, IoShieldOutline, IoCashOutline } from 'react-icons/io5';

const features = [
  {
    icon: <IoColorWandOutline size={24} />,
    title: 'Easy to install',
    description: 'Simply upload the code to your server, and your site will be online in no time with our easy-to-use installer.'
  },
  {
    icon: <IoCashOutline size={24} />,
    title: 'Recurring revenue',
    description: 'Start earning recurring revenue with our ready-to-go, subscription-based platforms.'
  },
  {
    icon: <IoServerOutline size={24} />,
    title: 'Self-hosted',
    description: 'Gain complete control over your data! Host and manage your own instance with our self-hosted scripts.'
  },
  {
    icon: <IoCodeOutline size={24} />,
    title: 'High quality code',
    description: 'Our high-quality, well-tested, and maintainable code will allow you to start any future project on a solid codebase.'
  },
  {
    icon: <IoCogOutline size={24} />,
    title: 'Highly configurable',
    description: 'Make your site truly yours. With over 100 available settings, you can make your site unique in feel and looks.'
  },
  {
    icon: <IoShieldOutline size={24} />,
    title: 'Advanced security',
    description: 'Continuous monitoring via static code analysis and vendor risk management ensures our code is up to standards.'
  },
];

const FeaturesSection = () => {
  return (
    <div className="bg-white py-5">
      <Container>
        <Row className="text-center">
          <Col lg={{ span: 8, offset: 2 }}>
            <h4 className="text-uppercase text-primary fw-semibold">Deploy faster</h4>
            <h3 className="mt-2 fw-bolder text-dark">
              Everything you need to deploy your app
              {/*Everything you need to get online*/}
            </h3>
            <p className="mt-3 lead">
              Save thousands of dollars and months of development with our ready-to-go, battle-tested SaaS solutions. Save time, buy code, make money!
            </p>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col lg={{ span: 10, offset: 1 }}>
            <Row className="g-4">
              {features.map((feature, index) => (
                <Col lg={6} key={index}>
                  <div className="d-flex">
                    <div className="me-3">
                      <div className="bg-primary text-white rounded-circle d-flex align-items-center justify-content-center" style={{ width: '40px', height: '40px' }}>
                        {feature.icon}
                      </div>
                    </div>
                    <div>
                      <h5 className="fw-semibold text-dark">{feature.title}</h5>
                      <p className="text-muted">{feature.description}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FeaturesSection;
