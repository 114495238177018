import React, { Component } from 'react'
import NotFoundPage from '../Containers/NotFoundPage';
import Home from '../Containers/Home';
import Product from '../Containers/Product';
import Services from '../Containers/Services';
import Contact from '../Containers/Contact';
import {Route, Switch} from "react-router-dom";

class AppNavigation extends Component {
  render(){
    return(
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/services" component={Services}/>
        <Route exact path="/contact" component={Contact}/>
        <Route exact path="/product/:slug" component={Product}/>
        <Route component={NotFoundPage}/>
      </Switch>

    )
  }
}

export default AppNavigation;
