import React, {useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import '../Styles/home.scss';
import Images from '../Styles/Images';
import FeaturesSection from "../Components/FeaturesSection";
import PricingSection from "../Components/PricingSection";
import Col from "react-bootstrap/Col";
import {getDocumentTitle, getProductBySlug, setDocumentTitle, useCurrentUrl} from "../Utils/helper";
import Row from "react-bootstrap/Row";
import {IoCubeOutline, IoStar} from "react-icons/io5";
import {Button, Image} from "react-bootstrap";
import MetaTags from "../Components/MetaTags";

const Product = () => {

  const { slug } = useParams();
  const history = useHistory();
  const product = getProductBySlug(slug);
  console.log(product)
  if (!product) {
    // Redirect to 404 page
    history.push('/404');
    return null; // Return null to avoid rendering anything
  }


  const pageTitle = `${product.name} - ${product.slogan}`;
  const pageDescription = product.description;
  const currentUrl = useCurrentUrl(); // Get the current URL using the utility function


  useEffect(() => {
    setDocumentTitle(pageTitle);
  }, []); // The empty dependency array ensures this runs only on component mount


  return (
    <div>
      <MetaTags
        title={getDocumentTitle(pageTitle)}
        description={pageDescription}
        ogTitle={getDocumentTitle(pageTitle, false)}
        ogDescription={pageDescription}
        ogUrl={currentUrl}
        ogImage={product.imageSrc}
      />
      <div className="">

        <Container className="py-4 py-md-6">
          <Row className="border-primary-subtle g-md-5">

            <Col md={6} className="border-1 mb-4 mb-md-5 mb-md-0">
              <img src={product.imageSrc} className="w-100 shadow-lg"/>
            </Col>

            <Col md={6} className="">
              <div className="d-flex mb-2">
                {[...Array(product.rating)].map((_, i) => (
                  <IoStar className="text-primary me-1 icon-xs" key={i}/>
                ))}
              </div>

              <h2 className="fw-bolder">{/*{product.name} - */}{product.slogan}</h2>
              <p className="text-muted"><a href={product.changelogUrl} className="" target="_blank" rel="noopener noreferrer">Version {product.version}</a> (Updated {product.lastUpdateDate}) {/*| <a href="#">Documentation</a>*/}
              </p>

              <p className="lead">{product.description}</p>

              <div className="d-flex justify-content-center  mt-5">

                <div className="d-flex justify-content-center align-items-center">
                  {/*<Button variant="primary">Buy</Button>*/}
                  <a href={product.envatoUrl} target="_blank" className="me-2" rel="noopener noreferrer">
                    <Button variant="primary" className="envato-button">
                      <div className="d-flex align-items-center">
                        <Image src={Images.envato_logo_white}
                               className="img-fluid"
                               style={{
                                 height: '32px',
                                 marginRight: '.5rem'
                               }}
                               alt="Custom Icon"
                        />
                        Buy on Envato
                      </div>
                    </Button>
                  </a>
                </div>


                <div className="d-flex justify-content-center align-items-center">
                  {/*<Button variant="primary">Buy</Button>*/}
                  <a href={product.demoUrl} target="_blank" className="me-2" rel="noopener noreferrer">

                    <Button variant="outline-primary" className="envato-button">
                      <div className="d-flex align-items-center">
                        {/*<Image src={Images.envato_logo_white}
                                                   className="img-fluid"
                                                   style={{
                                                       height: '32px',
                                                       marginRight: '.5rem'
                                                   }}
                                                   alt="Custom Icon"
                                            />*/}
                        <IoCubeOutline size={32} className="me-2"></IoCubeOutline>
                        Live Demo
                      </div>
                    </Button>
                  </a>
                </div>

              </div>


            </Col>
          </Row>

        </Container>
      </div>


      <div className="">
        <FeaturesSection/>
      </div>


      <div className="mt-5">
        <PricingSection product={product}/>
      </div>

      <div className="py-2">

      </div>

    </div>
  );
}

export default Product;
