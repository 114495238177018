import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container, Nav, Navbar } from "react-bootstrap";

import Images from "../Styles/Images";

const MainMenu = () => {
  // State to manage navbar expansion
  const [expanded, setExpanded] = useState(false);

  // Function to handle link clicks and close the navbar
  const handleNavLinkClick = () => {
    setExpanded(false);
  };

  return (
    <Navbar expand="lg" className="header bg-white" expanded={expanded}>
      <Container>
        <Navbar.Brand href="/">
          <img
            src={Images.logo_wide_light}
            className="d-inline-block align-top"
            alt="Qdev Techs logo"
          />
        </Navbar.Brand>

        {/* Toggle button to control the navbar collapse/expand */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />

        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="ml-auto">
            <Nav.Link to="/" as={NavLink} exact onClick={handleNavLinkClick}>
              Products
            </Nav.Link>
            <Nav.Link to="/services" as={NavLink} onClick={handleNavLinkClick}>
              Services
            </Nav.Link>
            <Nav.Link to="/contact" as={NavLink} onClick={handleNavLinkClick}>
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

MainMenu.propTypes = {
  history: PropTypes.object,
};

export default MainMenu;
