import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

const MetaTags = ({ title, description, ogTitle, ogDescription, ogUrl, ogImage }) => {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {ogTitle && <meta property="og:title" content={ogTitle} />}
      {ogDescription && <meta property="og:description" content={ogDescription} />}
      {ogUrl && <meta property="og:url" content={ogUrl} />}
      {ogImage && <meta property="og:image" content={ogImage} />}
      <meta property="og:type" content="website" />
      {/* Add more meta tags as needed */}
    </Helmet>
  );
};

MetaTags.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  ogUrl: PropTypes.string,
  ogImage: PropTypes.any,
};

MetaTags.defaultProps = {
  title: '',
  description: '',
  ogTitle: '',
  ogDescription: '',
  ogUrl: '',
  ogImage: '',
};

export default MetaTags;
